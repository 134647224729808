/*/
	Bootstrap + Popper (Custom Build)
	Based on https://stackoverflow.com/a/69957734 and
	https://github.com/cremadesign/bitsy/blob/main/www/assets/js/bootstrap.js
	
	import * as Popper from "@popperjs/core";
	
	import Alert from 'bootstrap/js/src/alert.js';
	import Button from 'bootstrap/js/src/button.js';
	import Carousel from 'bootstrap/js/src/carousel.js';
	import Collapse from 'bootstrap/js/src/collapse.js';
	import Dropdown from 'bootstrap/js/src/dropdown.js';
	import Modal from 'bootstrap/js/src/modal';
	import Offcanvas from 'bootstrap/js/src/offcanvas.js';
	import Popover from 'bootstrap/js/src/popover.js';
	import ScrollSpy from 'bootstrap/js/src/scrollspy.js';
	import Tab from 'bootstrap/js/src/tab.js';
	import Toast from 'bootstrap/js/src/toast.js';
	import Tooltip from 'bootstrap/js/src/tooltip.js';
	
	export default {
		Popper,
		Alert,
		Button,
		Carousel,
		Collapse,
		Dropdown,
		Modal,
		Offcanvas,
		Popover,
		ScrollSpy,
		Tab,
		Toast,
		Tooltip
	}
/*/

window.process = {
	env: {
		NODE_ENV: false
	}
}

import * as Popper from "@popperjs/core";

import Alert from 'bootstrap/js/src/alert.js';
import Button from 'bootstrap/js/src/button.js';
import Carousel from 'bootstrap/js/src/carousel.js';
import Collapse from 'bootstrap/js/src/collapse.js';
import Dropdown from 'bootstrap/js/src/dropdown.js';
import Modal from 'bootstrap/js/src/modal';
import Offcanvas from 'bootstrap/js/src/offcanvas.js';
import Popover from 'bootstrap/js/src/popover.js';
import ScrollSpy from 'bootstrap/js/src/scrollspy.js';
import Tab from 'bootstrap/js/src/tab.js';
import Toast from 'bootstrap/js/src/toast.js';
import Tooltip from 'bootstrap/js/src/tooltip.js'; 

export default {
	Popper,
	Alert,
	Button,
	Carousel,
	Collapse,
	Dropdown,
	Modal,
	Offcanvas,
	Popover,
	ScrollSpy,
	Tab,
	Toast,
	Tooltip
}
